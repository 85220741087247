<template>
    <v-container>
        <div>
            ImageGalleryRoute
        </div>
    </v-container>
</template>

<script>
    export default {
        name: 'ImageGalleryRoute'

    }
</script>

<style scoped>

</style>
